var bd;
import store from '@/store/index'
export const iniciarbd = () => {
    //indexedDB.deleteDatabase('bd_local')
    var solicitud = indexedDB.open('bd_local')
    solicitud.addEventListener("error", MostrarError)
    solicitud.addEventListener("success", Comenzar)
    solicitud.addEventListener("upgradeneeded", Creartablas)
}

function MostrarError(e) {
    alert("tenemos un error : " + e.code + e.message)
}

function Comenzar(e) {
    bd = e.target.result
    
    console.log("inciar BD")
}

function Creartablas(e) {
    var baseD = e.target.result
    console.log('version' + e.oldVersion)
    crea_tabla_clientes(baseD)
    crea_tabla_actualizacion(baseD)
    crea_tabla_productos(baseD)
    crea_tabla_monturas(baseD)
    crea_tabla_lentes_c(baseD)
}

export const guardar_datos = (tabla, datos) => {
    var transaccion = bd.transaction([tabla], "readwrite")
    var almacen = transaccion.objectStore(tabla)
    almacen.put(datos)
}

export const leer_datos = (tabla) => {
    return new Promise(function (resolve, reject) {
        var data = []
        var transaccion = bd.transaction([tabla])
        var almacen = transaccion.objectStore(tabla)
        var puntero = almacen.openCursor()
        puntero.onsuccess = (e) => {
            const cursor = e.target.result
            if (cursor) {
                data.push(cursor.value)
                cursor.continue();
            }else {
                resolve(data);
            }
        }
    })
}

export const leer_datos_montura = (tabla,ubicacion) => {
    return new Promise(function (resolve, reject) {
        var data = []
        var transaccion = bd.transaction([tabla])
        var almacen = transaccion.objectStore(tabla)
        var puntero = almacen.openCursor()
        puntero.onsuccess = (e) => {
            const cursor = e.target.result
            if (cursor) {
                if (cursor.value.ubicacion == ubicacion) {
                        data.push(cursor.value)
                }            
                cursor.continue();
            }else {
                resolve(data);
            }
        }
    })
}

window.addEventListener("load", iniciarbd)



function crea_tabla_productos(baseD) {
        var tabla3 = baseD.createObjectStore("productos",{keyPath:"id"})
    tabla3.createIndex("tipoproducto", "tipoproducto", { unique: false })
    tabla3.createIndex("operacion", "operacion", { unique: false })
    tabla3.createIndex("tipo_focal", "tipo_focal", { unique: false })
    tabla3.createIndex("marca", "marca", { unique: false })
    tabla3.createIndex("tratamiento", "tratamiento", { unique: false })
    tabla3.createIndex("tipo_material", "tipo_material", { unique: false })
    tabla3.createIndex("indice", "indice", { unique: false })
    tabla3.createIndex("descripcion", "descripcion", { unique: false })
    tabla3.createIndex("descripcion_corta", "descripcion_corta", { unique: false })
    tabla3.createIndex("costo", "costo", { unique: false })
    tabla3.createIndex("precio", "precio", { unique: false })
    tabla3.createIndex("stock", "stock", { unique: false })
    tabla3.createIndex("comision", "comision", { unique: false })
    tabla3.createIndex("c_palabra", "c_palabra", { unique: false })
    tabla3.createIndex("c_icono", "c_icono", { unique: false })
    tabla3.createIndex("laboratorio", "laboratorio", { unique: false })
    tabla3.createIndex("orden", "orden", { unique: false })
    tabla3.createIndex("filtro", "filtro", { unique: false })
    tabla3.createIndex("activo", "activo", { unique: false })
}

function crea_tabla_actualizacion(baseD) {
    var tabla2 = baseD.createObjectStore("actualizacion",{keyPath:"id"})
    tabla2.createIndex("tabla_clientes", "tabla_clientes", { unique: false })
}

function crea_tabla_clientes(baseD) {
    var tabla1 = baseD.createObjectStore("clientes",{keyPath:"id"})
    tabla1.createIndex("activo", "activo", { unique: false })
    tabla1.createIndex("tipodoc", "tipodoc", { unique: false })
    tabla1.createIndex("documento", "documento", { unique: false })
    tabla1.createIndex("nombre", "nombre", { unique: false })
    tabla1.createIndex("departamento", "departamento", { unique: false })
    tabla1.createIndex("provincia", "provincia", { unique: false })
    tabla1.createIndex("distrito", "distrito", { unique: false })
    tabla1.createIndex("ubigeo", "ubigeo", { unique: false })
    tabla1.createIndex("direccion", "direccion", { unique: false })
    tabla1.createIndex("telefono", "telefono", { unique: false })
    tabla1.createIndex("nacimiento", "nacimiento", { unique: false })
    tabla1.createIndex("edad", "edad", { unique: false })
    tabla1.createIndex("tienda", "tienda", { unique: false })
}

function crea_tabla_monturas(baseD) {
    var tabla4 = baseD.createObjectStore("monturas", { keyPath: "id" })
    tabla4.createIndex("tipoproducto", "tipoproducto", { unique: false })
    tabla4.createIndex("operacion", "operacion", { unique: false })
    tabla4.createIndex("tipo", "tipo", { unique: false })
    tabla4.createIndex("marca", "marca", { unique: false })
    tabla4.createIndex("modelo", "modelo", { unique: false })
    tabla4.createIndex("proveedor", "proveedor", { unique: false })
    tabla4.createIndex("color", "color", { unique: false })
    tabla4.createIndex("id_color", "id_color", { unique: false })
    tabla4.createIndex("material", "material", { unique: false })
    tabla4.createIndex("costo", "costo", { unique: false })
    tabla4.createIndex("precio_min", "precio_min", { unique: false })
    tabla4.createIndex("stock", "stock", { unique: false })
    tabla4.createIndex("precio", "precio", { unique: false })
    tabla4.createIndex("ubicacion", "ubicacion", { unique: false })
}
    function crea_tabla_lentes_c(baseD) {
        var tabla5 = baseD.createObjectStore("lentes_c",{keyPath:"id"})
    tabla5.createIndex("tipoproducto", "tipoproducto", { unique: false })
    tabla5.createIndex("operacion", "operacion", { unique: false })
    tabla5.createIndex("marca", "marca", { unique: false })
    tabla5.createIndex("tratamiento", "tratamiento", { unique: false })
    tabla5.createIndex("contenido", "contenido", { unique: false })
    tabla5.createIndex("descripcion", "descripcion", { unique: false })
    tabla5.createIndex("costo", "costo", { unique: false })
    tabla5.createIndex("precio", "precio", { unique: false })
    tabla5.createIndex("stock", "stock", { unique: false })

}