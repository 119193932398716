// Conveniently import this file anywhere to use db

import firebase from "firebase/app";
import "firebase/database";
import store from "@/store/index";

export const db = firebase.initializeApp(
  {
    apiKey: "AIzaSyA7suHTu_ush2-OyObleS8lQ1Hw9V3K_eg",
    authDomain: "opticenter-sms.firebaseapp.com",
    databaseURL: "https://opticenter-sms-default-rtdb.firebaseio.com",
    projectId: "opticenter-sms",
    storageBucket: "opticenter-sms.appspot.com",
    messagingSenderId: "19975693410",
    appId: "1:19975693410:web:4132e293f2b3d6d6bb5d3b",
  },
  "app2"
);

const empresas = db.database().ref("empresas");
const notificaciones = db.database().ref("notificaciones");

export const allEmpresas = () => {
  return empresas;
};

export const nuevaEmpresa = (id, array) => {
  return empresas.child(id).set(array);
};

export const eliminaEmpresa = (id) => {
  return empresas.child(id).remove();
};

export const actualizaEmpresa = (id, array) => {
  return empresas.child(id).set(array);
};

//----------------empresa---------------------
export const obtenerBD = (empresa) => {
  return empresas.child(empresa);
};

//--------------usuarios-----------
export const nuevoUsuario = (id, array) => {
  return db
    .database()
    .ref("usuarios")
    .child(id)
    .set(array);
};
export const allUsuarios = () => {
  return db.database().ref("usuarios");
};
export const buscarUsuarios = (id) => {
  return db
    .database()
    .ref("usuarios")
    .child(id);
};
export const primerUsuario = (id, correo, pass) => {
  db.database()
    .ref("usuarios")
    .child(id)
    .child("correo")
    .set(correo);
  db.database()
    .ref("usuarios")
    .child(id)
    .child("pass")
    .set(pass);
};
export const nuevoCampoUsuario = (id, campo, data) => {
  return db
    .database()
    .ref("usuarios")
    .child(id)
    .child(campo)
    .set(data);
};
export const guardaArchivo = (BD, selectedFile, ubicacion) => {
  const storageRef = db.storage().ref(BD + "/" + ubicacion);
  return storageRef.put(selectedFile);
};
//mensajes
export const all_mensajes = () => {
  return db
    .database()
    .ref(store.state.baseDatos.bd)
    .child("envios");
};
export const editar_mensajes = (id, data) => {
  return db
    .database()
    .ref(store.state.baseDatos.bd)
    .child("envios")
    .child(id)
    .set(data);
};
export const elimina_mensajes = (id) => {
  return db
    .database()
    .ref(store.state.baseDatos.bd)
    .child("envios")
    .child(id)
    .remove();
};
export const elimina_todo_mensajes = () => {
  return db
    .database()
    .ref(store.state.baseDatos.bd)
    .child("envios")
    .remove();
};
export const nuevo_mensajes = (data) => {
  db.database()
    .ref("mensajes/conexiones")
    .child(store.state.baseDatos.bd)
    .child("inicio_envio")
    .set(true);

  return db
    .database()
    .ref(store.state.baseDatos.bd)
    .child("envios")
    .push(data);
};
export const all_sms_predeterm = () => {
  return db
    .database()
    .ref(store.state.baseDatos.bd)
    .child("sms_predeterm");
};
export const nuevo_sms_predeterm = (id, array) => {
  return db
    .database()
    .ref(store.state.baseDatos.bd)
    .child("sms_predeterm")
    .child(id)
    .set(array);
};
export const all_grupos_cli = () => {
  return db
    .database()
    .ref(store.state.baseDatos.bd)
    .child("grupos_cli");
};
export const nuevo_grupo_cli = (id, array) => {
  return db
    .database()
    .ref(store.state.baseDatos.bd)
    .child("grupos_cli")
    .child(id)
    .set(array);
};
export const all_grupo = () => {
  return db
    .database()
    .ref(store.state.baseDatos.bd)
    .child("grupos");
};
export const nuevo_grupo = (array) => {
  return db
    .database()
    .ref(store.state.baseDatos.bd)
    .child("grupos")
    .push(array);
};
export const all_numeros_cli = () => {
  return db
    .database()
    .ref(store.state.baseDatos.bd)
    .child("lista_num");
};
export const all_numeros_cli_bd = (bd) => {
  return db
    .database()
    .ref(bd)
    .child("lista_num");
};
export const nuevo_numeros_cli = (id, array) => {
  return db
    .database()
    .ref(store.state.baseDatos.bd)
    .child("lista_num")
    .child(id)
    .set(array);
};
export const elimina_numeros_cli = (id) => {
  return db
    .database()
    .ref(store.state.baseDatos.bd)
    .child("lista_num")
    .child(id)
    .remove();
};
export const nuevo_qr = (data) => {
  return db
    .database()
    .ref("mensajes")
    .child("comandos/obtener_qr")
    .set(data);
};
export const descargar_usuarios = (data) => {
  return db
    .database()
    .ref("mensajes")
    .child("comandos/descarga_data")
    .set(data);
};
export const descargar_usuarios_api = (bd, data) => {
  return db
    .database()
    .ref("mensajes/conexiones")
    .child(bd)
    .child("descarga_user")
    .set(data);
};
export const obtener_qr = (id, nodo) => {
  return db
    .database()
    .ref("mensajes")
    .child("conexiones")
    .child(id)
    .child(nodo);
};
export const obtener_conexion = (id) => {
  return db
    .database()
    .ref("mensajes")
    .child("conexiones")
    .child(id);
};
export const eliminar_conexion = (id) => {
  return db
    .database()
    .ref("mensajes")
    .child("conexiones")
    .child(id)
    .remove();
};
export const nueva_conexion = (id, data) => {
  return db
    .database()
    .ref("mensajes")
    .child("conexiones")
    .child(id)
    .set(data);
};
export const all_envios = () => {
  return db
    .database()
    .ref(store.state.baseDatos.bd)
    .child("envios_sms");
};
export const graba_envios = (data) => {
  return db
    .database()
    .ref(store.state.baseDatos.bd)
    .child("envios_sms")
    .push(data);
};

export const obten_lista_rapida = (bd) => {
  return db
    .database()
    .ref("mensajes")
    .child("conexiones/" + bd + "/descarga_sms")
    .child("todos")
    .set(true);
};
export const lee_lista_rapida = (bd) => {
  return db
    .database()
    .ref("mensajes")
    .child("conexiones/" + bd + "/lista_sms_rapida");
};
export const modificar_lista_rapida = (bd, campo, data) => {
  return db
    .database()
    .ref("mensajes")
    .child("conexiones/" + bd + "/lista_sms_rapida")
    .child(campo)
    .set(data);
};
export const obten_conversacion = (bd, numero) => {
  db.database()
    .ref("mensajes")
    .child("conexiones/" + bd + "/descarga_sms")
    .child("num")
    .set(numero);
  return db
    .database()
    .ref("mensajes")
    .child("conexiones/" + bd + "/descarga_sms")
    .child("estado")
    .set(true);
};
export const leer_conversacion = (bd, numero) => {
  return db
    .database()
    .ref("mensajes")
    .child("conexiones/" + bd + "/lista_sms")
    .child(numero);
};
export const nuevo_mensajes_inmediato = (bd, data) => {
  db.database()
    .ref("mensajes/conexiones")
    .child(bd)
    .child("inicio_envio_inmediato")
    .set(true);
  return db
    .database()
    .ref("mensajes/conexiones")
    .child(bd)
    .child("envios_inmediato")
    .push(data);
};
