<template>
<v-dialog persistent v-model="$store.state.dialogoprogress" :max-width="tama">
    <v-card class="pa-12" v-if="$store.state.modo_progress==1">
        <v-progress-circular :rotate="90" :size="95" :width="15" color="primary" indeterminate>
            <v-icon @click="$store.commit('dialogoprogress')" color="red" small>mdi-close</v-icon>
        </v-progress-circular>
    </v-card>
    <v-card class="pa-12" v-if="$store.state.modo_progress==2">
        <h5 class="text-center">No interrumpa el proceso</h5>
        <v-progress-linear indeterminate color="blue-grey" height="25">
        </v-progress-linear>
    </v-card>
</v-dialog>
</template>

<script>
import store from '@/store/index'
export default {
    data() {
        return {

        }
    },
    computed: {
        tama() {
            var a = 200
            if (store.state.modo_progress == 2) {
                a = 400
            }
            return a
        }
    },
    methods: {

    }

}
</script>
