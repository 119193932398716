import Vue from 'vue'
import './plugins/fontawesome'
import './plugins/fontawesome'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import '@babel/polyfill'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
import { firestorePlugin } from 'vuefire'
import firebase from 'firebase'
import Croppa from 'vue-croppa';


Vue.use(Croppa);

Vue.use(firestorePlugin)

Vue.config.productionTip = false

let app = null

firebase.auth().onAuthStateChanged(() => {
  if (!app){
   app = new Vue({
      router,
      store,
      vuetify,
      render:h => h(App)
    }).$mount('#app')
  }
})

 