<template>
    <v-row justify="center">
        <v-dialog v-model="dial" fullscreen hide-overlay transition="dialog-bottom-transition">
            <v-card>
                <v-toolbar dense color="#008f39">
                    <v-btn icon dark @click="cierra()">
                        <v-icon color="red">mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title @click="abre_tienda" class="white--text">{{ tienda
                        }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn icon v-if="!showSearch" @click="toggleSearch">
                            <v-icon color="white">mdi-magnify</v-icon>
                        </v-btn>

                        <v-autocomplete @blur="showSearch = false" ref="searchField" v-if="showSearch" outlined dense
                            autofocus v-model="busca_p" :items="list_clientes" prepend-inner-icon="mdi-magnify"
                            placeholder="Buscar..." class="search-field " @click:append="busca()" @keyup.enter="busca()"
                            @change="busca()"></v-autocomplete>
                    </v-toolbar-items>
                </v-toolbar>
                <v-row dense class="mb-3">

                    <v-col cols="4" class="" style="max-height:70vh; overflow-y: auto;">
                        <v-card>
                            <v-row dense class="text-center">
                                <v-col cols="11" class="d-flex align-center justify-center">
                                    <v-text-field @input="handleInput" dense v-model="search" outlined hide-details
                                        prepend-inner-icon="mdi-magnify" placeholder="Buscar..."
                                        class="search-field mx-auto" @keyup.enter="enter"></v-text-field>
                                </v-col>
                                <v-col cols="1" class="d-flex align-center justify-center">
                                    <v-menu>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn icon v-bind="attrs" v-on="on">
                                                <v-icon>mdi-dots-vertical</v-icon>
                                            </v-btn>
                                        </template>
                                        <v-list dense>
                                            <v-list-item v-if="!fil_no_leido" @click='no_leidos()'>
                                                <v-list-item-icon>
                                                    <v-icon color="green">mdi-eye</v-icon>
                                                </v-list-item-icon>
                                                <v-list-item-title>No Leidos</v-list-item-title>
                                            </v-list-item>
                                            <v-list-item v-if="fil_no_leido" @click='no_leidos()'>
                                                <v-list-item-icon>
                                                    <v-icon color="red">mdi-eye</v-icon>
                                                </v-list-item-icon>
                                                <v-list-item-title>Todos</v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>

                                </v-col>
                            </v-row>

                            <v-list three-line class="message-list  mb-n2" style="max-height:70vh; overflow-y: auto;"
                                v-for="( item, index ) in  lista_filtrada " enter-class="feature-enter">
                                <v-subheader v-if="item.header">{{ item.header }}</v-subheader>
                                <v-divider v-else-if="item.divider" :key="index" :inset="item.inset"></v-divider>
                                <v-list-item v-else :key="item.title" @click="abre_conversa(item), selectItem(item)"
                                    dense class="mt-n5 mb-n2"
                                    :class="{ 'selected-message': selectedItem && selectedItem.id === item.id }">

                                    <v-list-item-avatar style="background-color: #e0e0e0;">
                                        <v-img :src="item.foto || '/user_sms.jpg'"></v-img>
                                    </v-list-item-avatar>
                                    <v-list-item-content class="align-items-center">
                                        <!-- Alineamos verticalmente el contenido -->
                                        <v-row no-gutters dense>
                                            <v-col cols="8">
                                                <v-list-item-title class="mb-1"
                                                    v-html="item.nombre ? item.nombre : item.id"></v-list-item-title>

                                                <!-- Aplicamos un margen inferior más pequeño -->

                                                <v-list-item-subtitle class="subtitle mb-1"
                                                    v-if="item.type == 'document'">
                                                    <v-icon class="">mdi-file-document-outline</v-icon>
                                                </v-list-item-subtitle>
                                                <v-list-item-subtitle class="subtitle mb-1" v-if="item.type == 'video'">
                                                    <v-icon class="">mdi-video-vintage</v-icon>
                                                </v-list-item-subtitle>
                                                <v-list-item-subtitle class="subtitle mb-1" v-if="item.type == 'image'">
                                                    <v-icon class="">mdi-image</v-icon>
                                                </v-list-item-subtitle>
                                                <v-list-item-subtitle class="subtitle mb-1" v-if="item.type == 'ptt'">
                                                    <v-icon class="">mdi-volume-high</v-icon>
                                                </v-list-item-subtitle>
                                                <v-list-item-subtitle class="subtitle mb-1"
                                                    v-if="item.type == 'sticker'"
                                                    v-html="'sticker'"></v-list-item-subtitle>
                                                <v-list-item-subtitle class="subtitle mb-1"
                                                    v-if="item.yo && item.type == 'chat'"
                                                    v-html="'Tú: ' + item.sms"></v-list-item-subtitle>
                                                <!-- Concatenamos el mensaje y el timestamp -->
                                                <v-list-item-subtitle class="subtitle mb-1"
                                                    v-if="!item.yo && item.type == 'chat'"
                                                    v-html="item.sms"></v-list-item-subtitle>
                                                <!-- Concatenamos el mensaje y el timestamp -->
                                            </v-col>
                                            <v-col cols="4" class="text-center">
                                                <v-list-item-subtitle class="timestamp pa-2"
                                                    v-html="conviertefecha(item.fecha)"></v-list-item-subtitle>
                                                <v-icon v-if="item.vista" class="mt-n2" color=red>mdi-new-box</v-icon>
                                            </v-col>
                                        </v-row>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-divider></v-divider>
                            </v-list>
                        </v-card>

                    </v-col>
                    <v-col cols="8" class="" style="max-height:70vh">
                        <v-card @scroll="handleScroll" id="conversation" class="pa-2 chat-background" height="60vh"
                            style="position: relative;"
                            :style="{ backgroundImage: `url(${require('@/assets/whatsapp_background.png')})`, backgroundRepeat: 'repeat' }">

                            <div class="message-container" v-for="item in sortedArraySms" :key="item.id">

                                <div v-if="!item.yo" class="message-receiver">
                                    <div class="receiver-bubble">
                                        <div v-if="item.type == 'chat'" class="message-text">{{ item.sms }}</div>
                                        <v-img v-if="item.type == 'sticker'" :src="item.sms" contain width="100"
                                            height="100"></v-img>
                                        <v-img v-if="item.type == 'image'" :src="item.sms" width="250"
                                            @click="openImageViewer(item.sms)"></v-img>
                                        <audio class="audio-player" v-if="item.type == 'ptt'" :src="item.sms"
                                            controls></audio>
                                        <video v-if="item.type == 'video'" :src="item.sms" controls
                                            class="video-player"></video>
                                        <a class="d-flex flex-column align-items-center" v-if="item.type == 'document'"
                                            :href="item.sms" target="_blank">
                                            <v-icon class="mb-2" x-large>mdi-cloud-download</v-icon>
                                            <p>Descargar</p>
                                        </a>
                                        <div class="timestamp">{{ conviertefecha(item.fecha) }}</div>
                                    </div>
                                </div>
                                <div v-else class="message-sender">
                                    <div class="sender-bubble">
                                        <div v-if="item.type == 'chat'" class="message-text">{{ item.sms }}</div>
                                        <v-img v-if="item.type == 'sticker'" :src="item.sms" contain width="100"
                                            height="100"></v-img>
                                        <v-img v-if="item.type == 'image'" :src="item.sms" width="250"
                                            @click="openImageViewer(item.sms)"></v-img>
                                        <audio class="audio-player" v-if="item.type == 'ptt'" :src="item.sms"
                                            controls></audio>
                                        <video v-if="item.type == 'video'" :src="item.sms" controls
                                            class="video-player"></video>
                                        <a class="d-flex flex-column align-items-center" v-if="item.type == 'document'"
                                            :href="item.sms" target="_blank">
                                            <v-icon class="mb-2" x-large>mdi-cloud-download</v-icon>
                                            <p>Descargar</p>
                                        </a>
                                        <div class="timestamp">{{ conviertefecha(item.fecha) }}</div>
                                    </div>
                                </div>

                            </div>

                        </v-card>
                        <v-card class="mx-auto mt-1">
                            <v-btn absolute v-if="showScrollButton" @click="scrollToBottom"
                                class="fab-bottom-right mt-n6" small color="white" dark rigth fab top>
                                <v-icon color="grey">mdi-arrow-down</v-icon>
                            </v-btn>
                            <v-row no-gutters class="pa-1">
                                <v-col cols="1" class="ml-n1">
                                    <v-menu offset-x>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon v-bind="attrs" v-on="on" class="mdi mdi-emoticon-happy-outline"
                                                large></v-icon>
                                        </template>
                                        <v-card outlined>
                                            <v-row dense>
                                                <v-col v-for="(emoji, index) in $store.state.emojis" :key="index"
                                                    cols="1">
                                                    <span class="emoji" @click="selectEmoji(emoji)">{{ emoji }}</span>
                                                </v-col>
                                            </v-row>
                                        </v-card>
                                    </v-menu>
                                </v-col>
                                <v-col cols="1" class="ml-n3 mr-n3">
                                    <v-menu offset-x>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon v-bind="attrs" v-on="on" class="mdi mdi-plus" large></v-icon>
                                        </template>
                                        <v-list dense>
                                            <v-list-item @click="selectImage">
                                                <v-list-item-icon>
                                                    <v-icon color="blue">mdi-image</v-icon>
                                                </v-list-item-icon>
                                                <v-list-item-content>
                                                    <v-list-item-title>Imagen</v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                </v-col>
                                <v-col cols="9">
                                    <v-text-field class="" ref="mensajeInput" autofocus label="Escribe un mensaje"
                                        outlined dense v-model="mensaje" @keyup.enter="enviar"
                                        :disabled="sending"></v-text-field>
                                </v-col>
                                <v-col cols="1">
                                    <v-icon v-if="mensaje != ''" large class="mdi mdi-send" @click="enviar"></v-icon>
                                </v-col>
                            </v-row>

                        </v-card>
                    </v-col>

                </v-row>

                <v-dialog scrollable transition="dialog-bottom-transition" hide-overlay class="mx-auto text-center"
                    v-model="imageDialog" max-width="650" max-height="70vh" @keydown.esc="closeDialog"
                    @keydown.native="handleBackButton">
                    <v-container fill-height>
                        <v-row align="center" justify="center">
                            <v-col cols="auto">
                                <v-img :src="selectedImage" width="150vh"></v-img>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-dialog>
                <v-dialog v-model="dial_tienda" scrollable max-width="500px">
                    <v-card>
                        <h4 class="text-center">SELECCIONE TIENDA</h4>
                        <v-simple-table class="elevation-1 mt-2" fixed-header height="70vh">
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th class="text-left">
                                            Tienda
                                        </th>
                                        <th class="text-left">
                                            B.Datos
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for=" item  in array_tiendas " :key="item.id" @click="select_tienda(item)">
                                        <td>{{ item.nom_tienda }}</td>
                                        <td>{{ item.bd }}</td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>

                    </v-card>
                </v-dialog>
                <input type="file" ref="fileInput" @change="handleFileChange" accept="image/*" style="display: none;">
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import store from "@/store/index";
import moment from "moment";
import firebase from 'firebase'
import axios from "axios"
import {
    all_numeros_cli_bd,
    nuevo_mensajes_inmediato,
    lee_lista_rapida,
    obten_conversacion,
    leer_conversacion,
    modificar_lista_rapida,
    obten_lista_rapida,
    nuevo_numeros_cli,
    guardaArchivo
} from "../../bd_ws.js";
export default {
    name: "caja",
    components: {},
    props: {
        data: [],
    },
    data() {
        return {
            array_tiendas: [{
                bd: 'BD1',
                nom_tienda: 'TODOS'
            },
            {
                bd: 'BD2',
                nom_tienda: 'MERCADERES'
            },
            {
                bd: 'BD3',
                nom_tienda: 'GRUPO'
            },
            {
                bd: 'BD4',
                nom_tienda: 'SAN AGUSTIN'
            },
            {
                bd: 'BD5',
                nom_tienda: 'GAMARRA'
            },
            {
                bd: 'BD6',
                nom_tienda: 'KVISION'
            },
            {
                bd: 'BD7',
                nom_tienda: 'VIRREY'
            },
            {
                bd: 'BD8',
                nom_tienda: 'ESPANA'
            }
            ],
            dial_tienda: false,
            mensaje: "",
            dial_sms: false,
            dial: false,
            desserts: [],
            items: [],
            array_sms: [],
            buscar: "",
            selecto: [],
            bd: "",
            tienda: "",
            sending: false,
            showScrollButton: false,
            imageDialog: false,
            selectedImage: '',
            showSearch: false,
            search: '',
            arra_clientes: [],
            list_clientes: [],
            busca_p: '',
            showScrollButton: false,
            loading: false,
            dial: false,
            selectedItem: null,
            lista_clientes: [],
            archivo: null,
            fil_no_leido: true,
        };
    },
    async created() {

        this.bd = 'BD4';
        if (store.state.permisos.master || store.state.permisos.optometria) {
            this.tienda = 'TODOS'
        } else {
            this.tienda = store.state.permisos.tienda
        }

        this.carga_clientes()
        this.dial = true;
    },

    mounted() {
        document.addEventListener('keydown', this.handleBackButton);
        lee_lista_rapida(this.bd).orderByChild("fecha").limitToLast(100).on("value", this.onDataChange);
    },
    beforeDestroy() {
        document.removeEventListener('keydown', this.handleBackButton);
        lee_lista_rapida(this.bd).off("value", this.onDataChange);
    },
    computed: {
        sortedArraySms() {
            return this.array_sms.sort((a, b) => new Date(a.fecha) - new Date(b.fecha));
        },
        lista_filtrada() {

            lee_lista_rapida(this.bd).orderByChild("fecha").limitToLast(100).on("value", this.onDataChange);
            if (this.tienda == 'TODOS') {
                var list = this.desserts
            } else {
                var list = this.desserts.filter(e => e.tienda == this.tienda)
            }
            if (this.fil_no_leido) {
                return list.filter((item) => item.vista == true);
            } else {

                return list.filter((item) => item.id.toLowerCase().includes(this.buscar.toLowerCase()));
            }

        },
    },

    methods: {
        no_leidos() {
            this.fil_no_leido = !this.fil_no_leido
        },
        closeDialog() {
            this.imageDialog = false;
        },
        handleBackButton(event) {
            // Verifica si el evento es el de retroceso del navegador móvil
            if (event.key === 'Backspace' || event.key === 'Escape') {
                this.closeDialog();
            }
        },
        abre_tienda() {
            if (store.state.permisos.master || store.state.permisos.optometria) {
                this.tienda = 'TODOS'
                this.dial_tienda = true
            }

        },
        selectImage() {
            this.$refs.fileInput.click();
        },
        handleFileChange(event) {
            const file = event.target.files[0];
            if (file) {
                this.archivo = file
                // Aquí puedes manejar el archivo seleccionado
                this.mensaje = file.name
                console.log('Archivo seleccionado:', file);
                // Puedes subirlo, mostrarlo en la interfaz, etc.
            }
        },
        select_tienda(data) {
            this.tienda = data.nom_tienda
            this.dial_tienda = false
        },
        async carga_clientes() {
            var array = []
            //store.commit("dialogoprogress", 1)
            var list = store.state.clientes.filter(e => e.telefono != '')
            for (var i = 0; i < list.length; i++) {
                var data = list[i]
                array.push({
                    documento: data.documento,
                    grupo: data.tienda,
                    nombre: data.nombre,
                    prefijo: '51',
                    telefono: data.telefono,
                    tienda: data.tienda,
                })
            }
            //console.log(array)
            this.lista_clientes = array
            /*var snapshot = await all_numeros_cli_bd(this.bd).once("value")
            if (snapshot.exists()) {
                snapshot.forEach((item) => {
                    array.push(item.val())
                })
                this.lista_clientes = array
                console.log(this.lista_clientes)
            }*/
        },
        busca() {
            this.num_cliente = this.busca_p.split('/')[2].trim()
            this.abre_conversa({
                id: this.num_cliente
            })
            this.$nextTick(() => {
                this.$refs.mensajeInput.focus(); // Devuelve el foco al campo de texto
            });
        },
        enter() {
            this.search = ''
        },
        async toggleSearch() {
            this.list_clientes = []

            for (var i = 0; i < this.lista_clientes.length; i++) {
                var array = this.lista_clientes[i]
                if (this.tienda == 'TODOS') {
                    this.list_clientes[this.list_clientes.length] = array.documento + ' / ' + array.nombre + ' / 51' + array.telefono

                } else {
                    if (array.tienda == this.tienda) {
                        this.list_clientes[this.list_clientes.length] = array.documento + ' / ' + array.nombre + ' / 51' + array.telefono
                    }
                }
            }
            this.busca_p = ''

            this.showSearch = !this.showSearch;
            if (this.showSearch) {

                this.$nextTick(() => {
                    this.$refs.searchField.focus();
                });
            }
        },
        handleInput(event) {
            this.buscar = this.search
        },

        async guardar_cl(data) {
            for (var i = 0; i < data.length; i++) {
                var item = data[i]
                var array = {
                    id: item.id,
                    prefijo: '51',
                    telefono: item.telefono,
                    documento: item.documento,
                    nombre: item.nombre,
                    nom_contacto: item.nombre,
                    observacion: 'sistema vision',
                    grupo: item.tienda,
                    id_: item.id,
                    tienda: item.tienda
                }
                nuevo_numeros_cli(array.id, array)
            }
            //nuevo_numeros_cli()
        },

        openImageViewer(imageUrl) {
            this.selectedImage = imageUrl;
            this.imageDialog = true;
        },
        handleScroll() {
            const conversation = document.getElementById('conversation');
            const isBottom = conversation.scrollHeight - conversation.scrollTop === conversation.clientHeight;
            this.showScrollButton = !isBottom;
        },
        scrollToBottom() {
            const conversation = document.getElementById('conversation');
            conversation.scrollTop = conversation.scrollHeight;
            this.showScrollButton = false;
        },
        async agregar_num() {
            var data = {
                id: "51" + this.buscar,
            };
            this.selecto = data;
            this.dial_sms = true;
            this.abre_conversa(data);
        },
        async enviar() {
            if (this.sending) return; // Evita el envío duplicado
            if (this.mensaje == "") {
                alert("Sin texto");
                return;
            }

            this.sending = true; // Establece el estado de envío a true

            var fecha = moment().unix();
            var array = {
                url: "",
                estado: "pendiente",
                fecha_crea: fecha,
                fecha_envio: fecha,
                mensaje: this.mensaje,
                numero: this.selecto.id,
            };
            if (this.archivo) {
                var url = await this.agregarFoto(this.archivo)
                array.mensaje = ''
                array.url = url
            }

            //console.log(array);

            try {
                await nuevo_mensajes_inmediato(this.bd, array);
            } catch (error) {
                console.error("Error enviando el mensaje:", error);
            } finally {
                this.sending = false; // Restablece el estado de envío a false
                this.mensaje = ""; // Limpia el campo de mensaje
                this.archivo = null
                this.$nextTick(() => {
                    this.$refs.mensajeInput.focus(); // Devuelve el foco al campo de texto
                });
            }
        },
        async agregarFoto(selectedFile) {
            try {
                // Iniciar el diálogo de progreso
                store.commit("dialogoprogress", 1);
                console.log(selectedFile.name);

                // Iniciar la tarea de guardado de archivo
                const task = guardaArchivo('images/', selectedFile, selectedFile.name);

                task.on('state_changed', snapshot => {
                    let porcentaje = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    this.Uploadvalue = porcentaje;
                });

                // Esperar a que la tarea termine
                await task;

                // Obtener la URL de descarga
                const url = await task.snapshot.ref.getDownloadURL();
                console.log("exito");
                // Limpiar el archivo seleccionado
                selectedFile = '';

                // Terminar el diálogo de progreso
                store.commit("dialogoprogress", 1);

                // Retornar la URL del archivo subido
                return url;
            } catch (error) {
                console.error('Error al subir el archivo:', error.message);
                throw new Error('No se pudo subir el archivo.');
            }
        },

        onDataChange(items) {
            let array = [];
            items.forEach((item) => {
                let data = item.val();

                var cli = store.state.clientes.find((e) => "51" + e.telefono === data.id)
                if (cli) {
                    data.nombre = cli.nombre
                    data.tienda = cli.tienda
                }
                if (data.id != undefined) {
                    array.push(data);
                }
            });
            array.sort(function (a, b) {
                if (a.fecha < b.fecha) {
                    return 1;
                }
                if (a.fecha > b.fecha) {
                    return -1;
                }
                // a must be equal to b
                return 0;
            });
            console.log(array)
            this.desserts = array;
            this.items = array;
        },

        async abre_conversa(data) {
            this.selecto = data;

            //await obten_conversacion(this.bd, data.id);
            modificar_lista_rapida(this.bd, data.id + '/vista', false)
            leer_conversacion(this.bd, data.id).orderByChild('fecha').limitToFirst(70).on("value", async (snapshot) => {
                var arr = [];
                this.array_sms = [];
                if (snapshot.exists()) {
                    //
                    snapshot.forEach((item) => {
                        arr.push(item.val());
                    });
                    this.array_sms = arr;
                    this.dial_sms = true;
                }
                this.scroo();
            });
            //this.scroo()
        },
        async scroo() {
            this.loading = true
            await new Promise((resolve) => setTimeout(resolve, 500));
            document.getElementById("conversation").scrollTop = document.getElementById("conversation").scrollHeight;
            await new Promise((resolve) => setTimeout(resolve, 500));
            document.getElementById("conversation").scrollTop = document.getElementById("conversation").scrollHeight;
            this.loading = false
        },
        conviertefecha(date) {
            var fec = moment.unix(date);
            const now = moment();
            if (now.diff(fec, "days") == 0) {
                return moment.unix(date).format("hh:mm A");
            } else {
                if (now.diff(fec, "days") < 7) {
                    return moment.unix(date).locale("es").format("dddd");
                } else {
                    return moment.unix(date).format("DD/MM");
                }
            }
        },
        selectEmoji(emoji) {
            // Selección de emoji
            this.mensaje += emoji;
            this.$nextTick(() => {
                this.$refs.mensajeInput.focus(); // Devuelve el foco al campo de texto
            });
        },
        cierra() {
            this.$emit("cierra", false);
        },
        router(view) {
            this.$router.push({
                name: view
            })
        },
        selectItem(item) {
            console.log(item)
            this.selectedItem = item;
        },
    },
};
</script>

<style>
.message-dialog {
    background-color: #f5f5f5;
}

.search-field {
    width: 300px;
}

.message-list {
    max-height: 100%;
    overflow-y: auto;
}

.chat-background {
    background-color: #f5f5f5;
    height: 60vh;
    overflow-y: auto;
}

.message-receiver {
    text-align: left;
    margin-bottom: 10px;
}

.message-sender {
    text-align: right;
    margin-bottom: 10px;
}

.receiver-bubble {
    background-color: #fff;
    padding: 10px;
    border-radius: 12px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    display: inline-block;
    max-width: 80%;
}

.sender-bubble {
    background-color: #dcf8c6;
    padding: 10px;
    border-radius: 12px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    display: inline-block;
    max-width: 80%;
}

.message-text {
    font-size: 14px;
    word-wrap: break-word;
}

.timestamp {
    font-size: 9px;
    color: #888;
    margin-top: 5px;
    text-align: right;
}

.message-container {
    margin-bottom: 7px;
}

.subtitle {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.emoji {
    cursor: pointer;
    font-size: 24px;
    padding: 8px;
}

.audio-player {
    width: 250px;
    /* Ajusta el ancho del reproductor de audio */
    height: 25px;
    /* Ajusta la altura del reproductor de audio */
}

.fab-bottom-right {
    bottom: 5px;
    /* Ajusta según sea necesario */
    right: 16px;
    /* Ajusta según sea necesario */
}

.video-player {
    width: 100%;
    max-width: 250px;
    /* Ajusta el tamaño según sea necesario */
    height: auto;
}

.search-field {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 1;
}

.selected-message {
    background-color: #e0f7fa !important;
}
</style>
