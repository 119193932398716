<template>
    <v-dialog v-model="dial" max-width="450" persistent>
        <div>
            <v-system-bar window dark>
                <v-icon large color="red" @click="cierra()">mdi-close</v-icon>
                <v-spacer></v-spacer>

                <v-spacer></v-spacer>
            </v-system-bar>
        </div>
        <v-card class="pa-2" v-if="data != 'transferencia'">
            <v-container>
                <v-img class="mx-auto" height="70" width="70" :src="logo"></v-img>
            </v-container>
            <h3 class="text-center">Numero : {{ num_pago }}</h3>
            <v-img class="mx-auto" height="400" width="400" :src="imagen"></v-img>
        </v-card>
        <v-card class="pa-2" v-if="data == 'transferencia'">
            <v-card class="pa-2">
                <v-row class="" no-gutters>
                    <v-col cols="4">
                        <v-container>
                            <v-img class="mx-auto" height="70" width="70" src="/bcp.png"></v-img>
                        </v-container>
                    </v-col>
                    <v-col cols="8">
                        <h4>BCP CTA.CTE.</h4>{{ array_pagos.BCP }}
                        <h4 v-if="array_pagos.BCP_I != ''">BCP CTA.CTE. INTERBANCARIA</h4>
                        <div>{{ array_pagos.BCP_I }}</div>
                    </v-col>
                </v-row>
            </v-card>
            <v-card class="pa-2 mt-3">
                <v-row class="" no-gutters>
                    <v-col cols="4">
                        <v-container>
                            <v-img class="mx-auto" height="70" width="70" src="/bbva.png"></v-img>
                        </v-container>
                    </v-col>
                    <v-col cols="8">
                        <h4>BCP CTA.CTE.</h4>{{ array_pagos.BBVA }}
                        <h4 v-if="array_pagos.BBVA_I != ''">BCP CTA.CTE. INTERBANCARIA</h4>{{ array_pagos.BBVA_I }}
                    </v-col>
                </v-row>
            </v-card>
        </v-card>
    </v-dialog>
</template>

<script>
import QR from 'qrcode-base64'
import {
    allModoPago
} from '../../db'
export default {
    props: {
        data: ''
    },
    data() {
        return {
            dial: false,
            imagen: '',
            logo: '',
            array_pagos: [],
            num_pago: ''
        }
    },
    created() {
        this.inicio()
    },

    computed: {

    },
    methods: {
        async inicio() {
            var snap = await allModoPago().once('value')
            if (snap.exists()) {
                console.log(this.data)
                if (this.data == 'yape') {
                    this.logo = '/yape.png'
                    this.num_pago = snap.val().num_yape
                    this.abre_qr(snap.val().YAPE)
                }
                if (this.data == 'plin') {
                    this.logo = '/plin.webp'
                    this.abre_qr(snap.val().PLIN)
                }
                if (this.data == 'transferencia') {
                    this.array_pagos = snap.val()
                    this.dial = true
                }
            } else {
                this.cierra()
            }

        },
        async abre_qr(data) {

            this.imagen = await QR.drawImg(data, {
                typeNumber: 4,
                errorCorrectLevel: 'M',
                size: 400,
                text: 'asd'
            })
            this.dial = true
        },
        cierra() {
            this.$emit('cierra', false)
        }

    },

}
</script>
